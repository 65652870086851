<template>
  <div class="row g-0 app-auth-wrapper">
    <div class="col-12 col-md-7 col-lg-6 auth-main-col text-center p-5">
      <div class="d-flex flex-column align-content-end">
        <div class="app-auth-body mx-auto">
          <div class="app-auth-branding mb-4">
            <a class="app-logo" href="index.html"
              ><img
                class="logo-icon mr-2"
                src="@/assets/images/app-logo.svg"
                alt="logo"
            /></a>
          </div>
          <h2 class="auth-heading text-center mb-5">Log in to Portal</h2>
          <div class="auth-form-container text-left">
            <p class="error-message">{{ message }}</p>
            <form class="auth-form login-form" @submit.prevent="login()">
              <div class="email mb-3">
                <label class="sr-only" for="phone">Phone</label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  class="form-control"
                  placeholder="Enter phone number"
                  required="required"
                  v-model="user.phone"
                />
              </div>
              <!--//form-group-->
              <div class="password mb-3">
                <label class="sr-only" for="signin-password">Password</label>
                <input
                  id="signin-password"
                  name="signin-password"
                  type="password"
                  class="form-control signin-password"
                  placeholder="Password"
                  required="required"
                  v-model="user.password"
                />
                <div class="extra mt-3 row justify-content-between">
                  <div class="col-6">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="RememberPassword"
                      />
                      <label class="form-check-label" for="RememberPassword">
                        Remember me
                      </label>
                    </div>
                  </div>
                  <!--//col-6-->
                  <div class="col-6">
                    <div class="forgot-password text-right">
                      <!-- <a href="reset-password.html">Forgot password?</a> -->
                    </div>
                  </div>
                  <!--//col-6-->
                </div>
                <!--//extra-->
              </div>
              <!--//form-group-->
              <div class="text-center">
                <button
                  type="submit"
                  class="btn app-btn-primary btn-block theme-btn mx-auto"
                  :disabled="isDisabled"
                >
                  Log In
                </button>
              </div>
            </form>

            <div class="auth-option text-center pt-5">
              No Account? Sign up
              <a class="text-link" href="#">here</a>.
            </div>
          </div>
          <!--//auth-form-container-->
        </div>
        <!--//auth-body-->
      </div>
      <!--//flex-column-->
    </div>
    <!--//auth-main-col-->
    <div class="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
      <div class="auth-background-holder"></div>
      <div class="auth-background-mask"></div>
      <div class="auth-background-overlay p-3 p-lg-5">
        <div class="d-flex flex-column align-content-end h-100">
          <div class="h-100"></div>
        </div>
      </div>
      <!--//auth-background-overlay-->
    </div>
    <!--//auth-background-col-->
  </div>
  <!--//row-->
</template>
<script>
export default {
  name: "Login",
  data: () => ({
    user: {
      phone: "",
      password: "",
    },
    isDisabled: false,
    message: ''
  }),
  mounted() {
    let body = document.body;
    body.classList.add("app-login");
    body.classList.add("p-0");
  },
  methods: {
    login: async function() {
      this.isDisabled = true;
      let success = await this.$store.dispatch('auth/login', this.user);
      if(success){
        this.$router.push('/');
        let body = document.body;
        body.classList.remove("app-login");
        body.classList.remove("p-0");
      }
      else
        this.message = "Phone number/password incorrect";
      this.isDisabled = false;
    }
  }
};
</script>
<style scoped>
  .error-message{
    text-align: center;
    color: red;
    font-weight: bold;
  }
</style>